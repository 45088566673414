<template>
  <section class="ArticleContent">
    <section v-for="(item, index) in data" :key="index">
      <h6 v-if="item.tit" :class="item.class"><span>{{ item.tit }}</span></h6>
      <div v-else-if="item.collapse">
        <el-collapse :v-model="'activeName' + index" accordion :class="'activeName' + index" @change="handleCollapseChange">
          <el-collapse-item  v-for="(org, i) in item.collapse" :key="i" :name="i + 1" @click.native="handleCollapseClick('activeName' + index)">
            <template slot="title">
              {{ org.tit }} <i :class="judgeActive('activeName' + index, i + 1) ? 'el-collapse-item__arrow el-icon-minus is-active' : 'el-collapse-item__arrow el-icon-plus'"></i>
            </template>
            <section v-for="(row, j) in org.content" :key="j" class="details-main-content">
              <h6 v-if="row.tit" :class="row.class"><span>{{ row.tit }}</span></h6>
              <p v-else-if="row.con">{{ row.con }}</p>
              <div v-else-if="typeof(row.url) == 'object' && row.url.length > 1">
                <el-row :gutter="30">
                  <el-col v-for="(pic, i) in row.url" :key="i" :span="row.url.length == 2 ? 12 : row.url.length == 3 ? 8 : 6"><img :src="pic" :class="row.class" /></el-col>
                </el-row>
              </div>
              <el-table v-else-if="row.table" :data="row.table.data" stripe style="width: 100%">
                <el-table-column prop="people" v-for="(flag, i) in row.table.header" :key="i" :label="flag.label" :width="flag.width" :align="flag.align">
                  <template slot-scope="scope">
                    <span v-if="flag.label != ''">{{scope.row[i]}}</span>
                    <el-progress v-else type="line" :text-inside="true" :stroke-width="20" :percentage="scope.row[i]"></el-progress>
                  </template>
                </el-table-column>
              </el-table>
              <div v-else-if="row.imgList">
                <el-row :gutter="10">
                  <el-col v-for="(imgUrl, i) in row.imgList" :key="i" :span="row.imgList.length == 2 ? 12 : row.imgList.length == 3 ? 8 : 6">
                    <section class="img-box">
                      <img :src="imgUrl.url" alt="">
                      <p v-if="imgUrl.con">{{ imgUrl.con }}</p>
                    </section>
                  </el-col>
                </el-row>
              </div>
              <img v-else :src="row.url" :class="row.class">
            </section>
          </el-collapse-item>
        </el-collapse>
      </div>
      <p v-else-if="item.con">{{ item.con }}</p>
      <div v-else-if="typeof(item.url) == 'object' && item.url.length > 1">
        <el-row :gutter="30">
          <el-col v-for="(pic, i) in item.url" :key="i" :span="item.url.length == 2 ? 12 : item.url.length == 3 ? 8 : 6"><img :src="pic" :class="pic.class"/></el-col>
        </el-row>
      </div>
      <el-table v-else-if="item.table" :data="item.table.data" stripe style="width: 100%">
        <el-table-column prop="people" v-for="(org, i) in item.table.header" :key="i" :label="org.label" :width="org.width" :align="org.align">
          <template slot-scope="scope">
            <span v-if="org.label != ''">{{scope.row[i]}}</span>
            <el-progress v-else type="line" :text-inside="true" :stroke-width="20" :percentage="scope.row[i]"></el-progress>
          </template>
        </el-table-column>
      </el-table>
      <div v-else-if="item.imgList">
        <el-row :gutter="10">
          <el-col v-for="(imgUrl, i) in item.imgList" :key="i" :span="item.imgList.length == 2 ? 12 : item.imgList.length == 3 ? 8 : 6">
            <section class="img-box">
              <img :src="imgUrl.url" alt="">
              <p v-if="imgUrl.con">{{ imgUrl.con }}</p>
            </section>
          </el-col>
        </el-row>
      </div>
      <img v-else :src="item.url" :class="item.class">
    </section>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      customElements: '',
      activeNames: '',
    }
  },
  methods: {
    //判断是否打开
    judgeActive (name, data){
      return this[name] == data
    },
    handleCollapseChange (activeNames) {
      this.activeNames = activeNames
    },
    handleCollapseClick (org) {
      this.customElements = org
      this[org] = this.activeNames
      this.judgeActive(org, this.activeNames)
      this.$forceUpdate()
    }
  }

}
</script>

<style>
  .ArticleContent {width: 100%; background-color: #fff;}
  .ArticleContent /deep/ .el-collapse-item__header {font-size: 1.1rem; font-weight: 600;}
  .el-collapse {padding: 1rem 4rem; background-color: #fff; border: none;}
  .ArticleContent h6 {font-weight: 600; font-size: 1rem; line-height: 2.4;}
  .ArticleContent p {text-indent: 2em; line-height: 2.4; font-size: 1rem;}
  .ArticleContent .el-collapse-item__arrow.is-active {transform: rotate(180deg)}
  .ArticleContent .el-collapse-item__arrow.el-icon-arrow-right {display: none;}
  .ArticleContent img {margin: 1rem auto}
  .ArticleContent img.w30 {display: inline-block; width: 30%; box-sizing: border-box; transform: translate(130%, 0);}
  .ArticleContent .img-box {text-align: center;}
  .ArticleContent .border-img {border: 1px solid #eee;}
</style>